import React, {Component} from 'react';
import AnimatedSlideShow from "./AnimatedSlideShow"
import LocalizedStrings from 'react-localization';

let slides = [
    {
        img: process.env.PUBLIC_URL + '/assets/images/TSMFront1.png',
        link: '#about'
    },
    {
        img: process.env.PUBLIC_URL + '/assets/images/TSMSolarPanel2.png',
        link: '#ecology'
    },
    {
        img: process.env.PUBLIC_URL + '/assets/images/TSMWarehouse2.png',
        link: '#partners'
    },

];


let strings = new LocalizedStrings({
    en:{
        show_menu:"Show menu",
        hide_menu:"Hide menu",
        home:"Home",
        description:"Description",
        partners:"Partners",
        services:"Services",
        follow_package: "Track Package",
        contact: "Contact",
        ecology: "Ecology",
    },
    fr: {
        show_menu:"Montrer menu",
        hide_menu:"Cacher  menu",
        home:"Accueil",
        description:"Description",
        partners: "Partenaires",
        services:"Services",
        follow_package: "Suivi Colis",
        contact: "Contact",
        ecology: "Ecologie",
    }
});

const options = [
    { value: 'fr', label: 'Français' },
    { value: 'en', label: 'English' },
]

export const NavBar = (props) => {
    strings.setLanguage(props.language);
    console.log(props.language);
    return (
        <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">{strings.show_menu}</a>
            <a className="mobile-btn" href="#home" title="Hide navigation">{strings.hide_menu}</a>

            <ul id="nav" className="nav">
                <img className="tsm-nav-logo" src={process.env.PUBLIC_URL + '/assets/images/tsm_logo.png'} alt="logo" style={{
                    position: "absolute",
                    left: "0px",
                    height: "100%"
                }}/>
                <h4 className="tsm-nav-text">{strings.tsmgroup}</h4>
                <li className="current"><a className="smoothscroll" href="/#home">{strings.home}</a></li>
                <li><a className="smoothscroll" href="/#about">{strings.description}</a></li>
                <li><a className="smoothscroll" href="/#partners">{strings.partners}</a></li>
                <li><a className="smoothscroll" href="/#services">{strings.services}</a></li>
                <li><a className="smoothscroll" href="/#ecology">{strings.ecology}</a></li>
                <li><a className="smoothscroll" href="/#follow-package">{strings.follow_package}</a></li>
                <li><a className="smoothscroll" href="/#contact">{strings.contact}</a></li>
                <li>
                    <select className="languageSelect" value={props.language} onChange={props.onLanguageChange} style={{
                        backgroundColor: "transparent",
                        color: "white",
                        // position: "absolute",
                        // right: '1vw',
                        // top: '0'
                    }} >
                        <option value="fr">Français</option>
                        <option value="en">English</option>
                    </select>
                </li>
            </ul>
        </nav>
    );
}


const Header = (props) => {
        return (
            <header id="home">
                <NavBar language={props.language} onLanguageChange={props.onLanguageChange} />
                <AnimatedSlideShow slides={slides} language={props.language}/>
            </header>
        );
}

export default Header;
