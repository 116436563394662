import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LegalMentions from "./LegalMention";
import DataProtection from "./DataProtection";

 ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={ <App /> }/>
                    <Route path="/legal-mentions" element={ <LegalMentions /> }/>
                    <Route path="/data-protection" element={ <DataProtection /> }/>
                </Routes>
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById('root')
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
