import React, {Component, useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import $ from 'jquery';
import {apiUrl} from "../App";

const Marker = ({element}) =>
    <div style={{width: 0, height: 0}}>
        <span style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            width: "30px",
            height: "30px",
            color: "#fff",
            background: "#3e5cb6",
            border: "solid 2px",
            borderRadius: "0 70% 70%",
            boxShadow: "0 0 2px #000",
            cursor: "pointer",
            transformOrigin: "0 0",
            transform: "rotateZ(-135deg)"
        }}><b style={{transform: "rotateZ(135deg)"}}>O</b></span>
    </div>

const RoundMap = props => {

    const getHeaders = () => {
        const token = localStorage.getItem('token');
        return {
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Token ' + token
        };
    }

    useEffect(() => {
        // let request = $.ajax({
        //     url: `${apiUrl}/RoundPackage/?round=${this.state.round}`,
        //     type: "get",
        //     headers: getHeaders()
        // });
        //
        // // Callback handler that will be called on success
        // request.done(function (response, textStatus, jqXHR) {
        //     const rps = response.results;
        //     const rpsPerPackage = rps.reduce((a, v) => ({...a, [v.package]: v}), {});
        //     let request = $.ajax({
        //         url: `${apiUrl}/Package/?id__in=${response.results.map(e => e.package).join(',')}`,
        //         type: "get",
        //         headers: getHeaders(),
        //         success: (data) => {
        //             // setState(
        //             //     {
        //             //         packages: data.results.map(e => ({...e, number: rpsPerPackage[e.id].number}))
        //             //     }
        //             // );
        //         }
        //     });
        //
        // });
        //
        // // Callback handler that will be called on failure
        // request.fail(function (jqXHR, textStatus, errorThrown) {
        //     // Log the error to the console
        //     console.error(
        //         "The following error occurred: " +
        //         textStatus, errorThrown
        //     );
        // });
        //
        // // Callback handler that will be called regardless
        // // if the request failed or succeeded
        // request.always(function () {
        //     // Reenable the inputs
        //     // $inputs.prop("disabled", false);
        //     // rThis.setState({loading: false});
        // });
    });

    console.log(this.state);
    return (
        // Important! Always set the container height explicitly
        <div style={{height: '80vh', width: '100%'}}>
            {/*<div style={{textAlign: "center", paddingTop: "20px"}}>*/}
            {/*    <Loading/>*/}
            {/*</div>*/}
            <GoogleMapReact
                bootstrapURLKeys={{key: 'AIzaSyAoTalM-XhIHWZxYchiAUnZPHJjWRXc9W4'}}
                defaultCenter={{lat: 42.6887, lng: 2.8948}}
                defaultZoom={10}
            >
                {this.state.packages.map((prop, key) => {
                    return <Marker
                        key={key}
                        element={prop}
                        lat={prop.package.addressPoint.lat}
                        lng={prop.package.addressPoint.lon}
                    />;
                })}
            </GoogleMapReact>
        </div>
    );

}

export default RoundMap;
