import React, {useState} from "react"
import {
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel, makeStyles, MenuItem,
    Select,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import $ from "jquery";

const initialValues = {
    // First step : Common infos
    name: "",
    packageIdentifier: "",
    mail: "",
    phoneNumber: "",
    detail: "",
    address: "",
    city: "",
    zipCode: "",
    reasonOther: "",
    reason: 0,
}

const fieldsValidation = {
    name: {
        error: "",
        validate: "text",
        minLength: 2,
        maxLength: 20
    },
    mail: {
        error: "",
        validate: "email"
    },
    phoneNumber: {
        error: "",
        validate: "phone",
        maxLength: 15
    },
}

const isComplexText = RegExp(/^[a-zA-Z0-9àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ ]+$/)
const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)

function formValidation(name, value, schema) {
    const {validate, minLength, maxLength} = schema[name]
    let error = ""

    if (minLength && value.length < minLength) error = `Minimum of ${minLength} characters are required.`
    else if (maxLength && value.length > maxLength) error = `Maximum length of ${maxLength} exceeded!`
    if (!validate) return

    switch (validate) {
        case "text":
            if (!isComplexText.test(value)) error = "This field accepts text only."
            break
        case "email":
            if (!isEmail.test(value)) error = "Please enter a valid email address."
            break
        default:
            break
    }

    return error
}

const FormTextField = withStyles({
    root: {
        '& label': {
            color: 'white',
            fontSize: "10pt"
        },
        // '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        // },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'lightblue',
            },
            '&.Mui-focused fieldset': {
                // borderColor: 'green',
            },
            color: 'white',
            fontSize: '12pt'
        },
    },
})(TextField);

const useStyles = makeStyles(
    {
        root: {
            '& label': {
                color: 'white',
                fontSize: "10pt"
            },
            // '& .MuiInput-underline:after': {
            //     borderBottomColor: 'green',
            // },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'white',
                },
                '&:hover fieldset': {
                    borderColor: 'lightblue',
                },
                '&.Mui-focused fieldset': {
                    // borderColor: 'green',
                },
                color: 'white',
                fontSize: '12pt'
            },
        },
    }
);

// export const apiUrl = "http://localhost:8000/api/";
export const apiUrl = "https://www.groupetsm-api.com/api/";

const ContactForm = (props) => {
        const [formValues, setFormValues] = useState(initialValues);
        const [formErrors, setFormErrors] = useState({});
        const classes = useStyles();

        // Handle form change
        const handleChange = e => {
            const {name, value, checked, type} = e.target;

            // Set values
            setFormValues(prev => ({
                ...prev,
                [name]: value
            }))

            if (name in fieldsValidation) {
                // set errors
                const error = formValidation(name, value, fieldsValidation) || ""

                setFormErrors({
                    [name]: error
                });
            }
        }

        function searchPackage(){
            if(formValues.packageIdentifier.length < 8)
                return;
            let request = $.ajax({
                url: `${apiUrl}Package/?identifier=${formValues.packageIdentifier}`,
                dataType: 'json',
                contentType: 'application/json',
                headers: {
                    'accept': 'application/json',
                    'authorization': 'Token 57bf4cf65514eac204c524f8888d1cd7338750b4'
                }
            });
            console.log("Test");
            // Callback handler that will be called on success
            request.done(function (response, textStatus, jqXHR) {
                console.log(response);
                const res = response.results;
                if(res && res.length > 0){
                    const p = res[0];
                    console.log(p);
                    setFormValues(prev => ({
                        ...prev,
                        name: p.receiver,
                        mail: p.email,
                        phoneNumber: p.phoneNumber,
                        address: p.address,
                        city: p.city.name,
                        zipCode: p.city.zipCode.zipCode
                    }));
                }
            });
        }

        const submit = (e) => {
            e.preventDefault();
            // if(this.handleValidation()){
            // setup some local variables
            let $form = $("form");

            // Let's select and cache all the fields
            let $inputs = $form.find("input, select, button, textarea");

            // Serialize the data in the form
            let serializedData = $form.serializeArray();
            let jsonData = {};

            $.map(serializedData, function (n, i) {
                jsonData[n['name']] = n['value'];
            });
            jsonData = {
                ...jsonData,
                // address: `${jsonData["address"]}, ${jsonData["city"]}, ${jsonData["zipCode"]}`,
            }
            if(!jsonData["reason"])
                jsonData["reason"] = 0;
            console.log(jsonData);

            // Let's disable the inputs for the duration of the Ajax request.
            // Note: we disable elements AFTER the form data has been serialized.
            // Disabled form elements will not be serialized.
            $inputs.prop("disabled", true);

            let request = $.ajax({
                url: `${apiUrl}Complain/`,
                type: "post",
                data: JSON.stringify(jsonData),
                dataType: 'json',
                contentType: 'application/json',
                headers: {
                    'accept': 'application/json',
                    'authorization': 'Token 57bf4cf65514eac204c524f8888d1cd7338750b4'
                }
            });
            // Callback handler that will be called on success
            request.done(function (response, textStatus, jqXHR) {
                props.setParentState();
                $inputs.each(function (index, obj) {
                    obj = $(obj);
                    if (obj.prop('type') !== "submit")
                        obj.val("");
                });
            });

            // Callback handler that will be called on failure
            request.fail(function (jqXHR, textStatus, errorThrown) {
                // Log the error to the console
                console.error(
                    "The following error occurred: " +
                    textStatus, errorThrown
                );
            });

            // Callback handler that will be called regardless
            // if the request failed or succeeded
            request.always(function () {
                // Reenable the inputs
                $inputs.prop("disabled", false);
            });
            // } else {
            //     console.log(this.state.errors);
            // }
        }


        return (
            <form action="/mail" method="post" id="contactForm" name="contactForm"
                  onSubmit={submit}
            >
                <Grid container spacing={2} noValidate>
                    <Grid item xs={12} sm={6}>
                        <FormTextField
                            fullWidth
                            label="Nom"
                            name="name"
                            placeholder="Nom"
                            margin="normal"
                            variant="outlined"
                            value={formValues.name || ""}
                            onChange={handleChange}
                            error={!!formErrors.name}
                            helperText={formErrors.name}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormTextField
                            fullWidth
                            label="N° Colis"
                            name="packageIdentifier"
                            placeholder="N° Colis"
                            margin="normal"
                            variant="outlined"
                            value={formValues.packageIdentifier}
                            onChange={e => {
                                handleChange(e);
                                searchPackage();
                            }}
                            error={!!formErrors.packageIdentifier}
                            helperText={formErrors.packageIdentifier}
                            required
                            inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormTextField
                            fullWidth
                            label="Email"
                            name="mail"
                            placeholder="Adresse e-mail"
                            type="email"
                            value={formValues.mail || ""}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={!!formErrors.mail}
                            helperText={formErrors.mail}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormTextField
                            fullWidth
                            label="Téléphone"
                            name="phoneNumber"
                            placeholder="Numéro de téléphone"
                            type="tel"
                            inputProps={{pattern: "^((\\+\\d{1,3}(-| )?\\(?\\d\\)?(-| )?\\d{1,5})|(\\(?\\d{2,6}\\)?))(-| )?(\\d{3,4})(-| )?(\\d{4})(( x| ext)\\d{1,5}){0,1}$"}}
                            value={formValues.phoneNumber || ""}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={!!formErrors.phoneNumber}
                            helperText={formErrors.phoneNumber}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormTextField
                            fullWidth
                            label="Adresse"
                            name="address"
                            placeholder="Adresse"
                            value={formValues.address || ""}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={!!formErrors.address}
                            helperText={formErrors.address}
                            required
                        />
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <FormTextField
                            fullWidth
                            label="Ville"
                            name="city"
                            placeholder="Ville"
                            value={formValues.city || ""}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={!!formErrors.city}
                            helperText={formErrors.city}
                            required
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormTextField
                            fullWidth
                            label="Code-postal"
                            name="zipCode"
                            placeholder="Code postal"
                            value={formValues.zipCode || ""}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={!!formErrors.zipCode}
                            helperText={formErrors.zipCode}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl classes={classes} fullWidth margin="normal">
                            <InputLabel id="select-label">Motif</InputLabel>
                            <Select
                                labelId="select-label"
                                id="reason"
                                name="reason"
                                variant="outlined"
                                margin="normal"
                                value={formValues.reason}
                                onChange={handleChange}
                            >
                                <MenuItem value={0}>Colis non reçu</MenuItem>
                                <MenuItem value={1}>Demande de relivraison</MenuItem>
                                <MenuItem value={2}>Colis endommagé</MenuItem>
                                <MenuItem value={3}>Changement d’adresse</MenuItem>
                                <MenuItem value={4}>Autres</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {formValues.reason === 4 && <Grid item xs={12} sm={6}>
                        <FormTextField
                            fullWidth
                            label="Détail"
                            name="reasonOther"
                            placeholder="Détail"
                            value={formValues.reasonOther || ""}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={!!formErrors.reasonOther}
                            helperText={formErrors.reasonOther}
                        />
                    </Grid>}
                    <Grid item xs={12}>
                        <FormTextField
                            fullWidth
                            label="Description"
                            name="detail"
                            placeholder="Description"
                            value={formValues.detail || ""}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={!!formErrors.detail}
                            helperText={formErrors.detail}
                            required
                            multiline
                        />
                    </Grid>
                </Grid>
                <div style={{display: "flex", marginTop: 50, justifyContent: "flex-end"}}>
                    <Button variant="contained" color="primary" type="submit">
                        Envoyer
                    </Button>
                </div>
            </form>
        )
    }
;

export default ContactForm
