import React, {Component} from 'react';
import '@material/react-snackbar/dist/snackbar.css';
import {Snackbar} from '@material/react-snackbar';
import $ from "jquery";
import Select from 'react-select'
import LocalizedStrings from "react-localization";
import ContactForm from "./ContactForm";


function GetNavBar(props) {
    if (props.value)
        return (<Snackbar
            message="Message envoyé !"
            actionText="Fermer"
        />);
    return (<div></div>);
}

const selectOptions = [
    {value: 'support', label: 'Support'},
    {value: 'job', label: 'Opportunités d\'emploi'},
    {value: 'other', label: 'Autre'}
]

let strings = new LocalizedStrings({
    en: {
        contact: "Contact",
        email: "Email",
        subject: "Subject",
        support: "Support",
        infos: "Informations",
        other: "Other",
        message: "Message",
        send: "Send",
        fillIdentifier: "Fill packageNumber to auto-complete known informations"
    },
    fr: {
        contact: "Contact",
        email: "Email",
        subject: "Sujet",
        support: "Support",
        infos: "Informations",
        other: "Autres",
        message: "Message",
        send: "Envoyer",
        fillIdentifier: "Remplissez le N°colis pour une auto-complétion des informations connues"
    }
});

class Contact extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            fields: {},
            errors: {},
            mailSent: false
        }
    }

    // handleValidation() {
    //     let fields = this.state.fields;
    //     let errors = {};
    //     let formIsValid = true;
    //
    //     //Email
    //     if (!fields["contactEmail"]) {
    //         formIsValid = false;
    //         errors["contactEmail"] = "Nous avons besoin de votre email pour vous contacter";
    //     }
    //
    //     if (typeof fields["contactEmail"] !== "undefined") {
    //         let lastAtPos = fields["contactEmail"].lastIndexOf('@');
    //         let lastDotPos = fields["contactEmail"].lastIndexOf('.');
    //
    //         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["contactEmail"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["contactEmail"].length - lastDotPos) > 2)) {
    //             formIsValid = false;
    //             errors["contactEmail"] = "L'adresse n'est pas valide";
    //         }
    //     }
    //
    //     if (!formIsValid)
    //         errors["submit"] = "Veuillez corriger les erreurs";
    //
    //     this.setState({errors: errors});
    //     return formIsValid;
    // }

    // handleChange(field, e) {
    //     let fields = this.state.fields;
    //     fields[field] = e.target.value;
    //     this.setState({fields});
    // }

    render() {
        strings.setLanguage(this.props.language);
        return (
            <section id="contact">
                <GetNavBar value={this.state.mailSent}/>
                <div className="row section-head">

                    <div className="two columns header-col">

                        <h1><span></span></h1>

                    </div>

                    <div className="ten columns" style={{'textAlign': 'center'}}>
                        <h3 className="lead">{strings.contact}</h3>
                        <h6>{strings.fillIdentifier}</h6>
                    </div>

                </div>

                <div className="row">
                    <div className="twelve columns">
                        <ContactForm setParentState={() => this.setState({mailSent: true})}/>
                    </div>
                </div>
            </section>
        );
    }
}

// <form action="/mail" method="post" id="contactForm" name="contactForm"
//       onSubmit={this.contactSubmit.bind(this)}>
//     <fieldset>
//         <div>
//             <label htmlFor="contactEmail">{strings.email} <span
//                 className="required">*</span></label>
//             <input type="email" defaultValue="" size="50" id="contactEmail" name="contactEmail"
//                    required
//                    pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"
//                    onChange={this.handleChange.bind(this, "email")}
//                    value={this.state.fields["email"]}/>
//         </div>
//         <div>
//             <label htmlFor="contactSubject">{strings.subject}</label>
//             <select id="contactSubject" name="contactSubject"
//                     value={this.state.fields["subject"]}
//                     onChange={this.handleChange.bind(this, "subject")} required={true}>
//                 <option value="support">{strings.support}</option>
//                 <option value="infos">{strings.infos}</option>
//                 <option value="other">{strings.other}</option>
//             </select>
//         </div>
//         <div>
//             <label htmlFor="contactMessage">{strings.message}<span className="required">*</span></label>
//             <textarea cols="50" rows="15" id="contactMessage" name="contactMessage"
//                       required/>
//         </div>
//         {/* Weird fix */}
//         <div style={{textAlign: "left"}}>
//             <label htmlFor="submit"> </label>
//             <input className="submit" name="submit" onSubmit={this.contactSubmit.bind(this)}
//                    type="submit" value={strings.send}/>
//         </div>
//     </fieldset>
// </form>
//
// <div id="message-warning"> Error</div>
// <div id="message-success">
//     <i className="fa fa-check"></i>Your message was sent, thank you!<br/>
// </div>

export default Contact;
