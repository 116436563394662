import React, { Component } from 'react';
import {Link} from "react-router-dom";

class Footer extends Component {
  render() {

    if(this.props.data){

    }

    return (
      <footer>

     <div className="row">
        <div className="twelve columns">
           <ul className="footer-infos">
               <li key="address"><p>7 RUE DES CAROUBIERS</p></li>
               <li key="siret"><p>N°SIRET : 83447685500020</p></li>
           </ul>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Link to={`/legal-mentions`} >Mentions Légales</Link> | <Link to="/data-protection">Protection des données</Link>
            </div>
        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
     </div>
  </footer>
    );
  }
}

export default Footer;
