import React, {Component} from 'react';
import ReactPlayer from 'react-player/file'
import $ from 'jquery';
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    en: {
        ecology: 'Ecology',
        ecologyDesc1: "Because taking care of the planet is a common effort anybody need to do something," +
            "that's why we installed solar panels on top of our warehouse to reduce our environmental impact.",
        ecologyDesc2: "Furthermore we are equipped with some clean vehicles, emitting no pollution," +
            "we plan to increase this number to become a more ecological and responsible company."
    },
    fr: {
        ecology: "Écologie",
        ecologyDesc1: "Parce que prendre soin de la planète est un effort commun que tout le monde doit le prendre au sérieux, " +
            "nous avons équipé notre entrepôt de panneaux solaire afin de réduire notre impact sur l'environement. ",
        ecologyDesc2: "De plus nous possèdont des véhicules propres, n'émettant pas de pollution, " +
            "et nous comptons bien augmenter ce nombre afin de devenir une entreprise encore plus responsable et écologique. "
    }
});

class Ecology extends Component {

    state = {
        // Player state
        playing: false,
        controls: true,
        volume: 0,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
    }

    handleProgress = state => {
        // console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    ref = player => {
        this.player = player
    }

    render() {
        const {playing, controls, volume, muted, loop, played, loaded, duration, playbackRate} = this.state;
        strings.setLanguage(this.props.language);
        return (
            <section id="ecology">
                <div className="row">
                    <div className="main-col twelve col">
                        <h1>{strings.ecology}</h1>
                        <p>{strings.ecologyDesc1}</p>
                        <ReactPlayer
                            ref={this.ref}
                            className='react-player'
                            width='70%'
                            height='70%'
                            url={process.env.PUBLIC_URL + '/assets/video/TSMShort.webm'}
                            playing={playing}
                            controls={controls}
                            loop={loop}
                            playbackRate={playbackRate}
                            volume={volume}
                            muted={muted}
                            onStart={() => console.log('onStart')}
                            // onSeek={e => console.log('onSeek', e)}
                            onProgress={this.handleProgress}
                        />
                        <p>{strings.ecologyDesc2}</p>
                    </div>
                </div>
            </section>
        );
    }
}

export default Ecology;
