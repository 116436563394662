import './App.scss';
import React, {Component, useState} from "react";
import Header, {NavBar} from "./components/Header";
import About from "./components/About";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import {BrowserRouter, useLocation} from "react-router-dom";
import Services from "./components/Services";
import PackageFollow from "./components/PackageFollow";
import Partners from "./components/Partners";
import LocalizedStrings from 'react-localization';
import Ecology from "./components/Ecology";
import AnimatedSlideShow from "./components/AnimatedSlideShow";


let strings = new LocalizedStrings({
    'fr': {
        '': ''
    }
});


const LegalMentions = (props) => {
    const [lang, setLang] = useState(strings.getLanguage());

    const handleChangeLanguage = (e) => {
        let val = e.target.value;
        setLang(val);
    }

    return (
        <div className="App">
            <div style={{
                height: "6vh",
                backgroundColor: "#ccc"
            }}/>
            <NavBar language={lang} onLanguageChange={handleChangeLanguage}/>
            <div className="not-home">
                <section id="about">
                    <div className="row">
                        <div className="main-col twelve col">
                            <h5 style={{color: "lightgrey"}}>Conformément aux dispositions de la loi n°2004-575 du 21
                                juin 2004 pour la confiance dans l’économie numérique,
                                il est porté à la connaissance des utilisateurs du site groupetsm.fr (ci-après le « Site
                                ») les informations suivantes :</h5>
                            <br/>
                            <h5>Le site est édité par la société Xentech</h5>
                            <p><b>Forme : </b> Micro-entreprise</p>
                            <p><b>Adresse : </b> 10 Place de la République, 66270 Le Soler</p>
                            <p><b>N° TVA Intracommunautaire : </b> FR50893209510</p>
                            <p><b>N° Téléphone : </b> 0625774453</p>
                            <p style={{color: "red"}}><b>Je n'ai pas d'informations sur vos colis merci de ne pas
                                m'appeler à ce sujet</b></p>
                            <p><b>Email : </b> clement.mitjana@xentech.pro</p>
                            <br/>
                            <h5>Le site est hébergé par la société Heroku Inc. </h5>
                            <p><b>Forme : </b> Filiale</p>
                            <p><b>Adresse : </b> 650 7th Street San Francisco, CA USA</p>
                        </div>
                    </div>
                </section>

            </div>
            <Footer language={lang}/>
        </div>
    );
}

export default LegalMentions;
