import './App.scss';
import React, {Component} from "react";
import Header from "./components/Header";
import About from "./components/About";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import {BrowserRouter, useLocation} from "react-router-dom";
import Services from "./components/Services";
import PackageFollow from "./components/PackageFollow";
import Partners from "./components/Partners";
import LocalizedStrings from 'react-localization';
import Ecology from "./components/Ecology";
import AnimatedSlideShow from "./components/AnimatedSlideShow";


class App extends Component {

    constructor(props) {
        super(props);
        let strings = new LocalizedStrings({
            'fr': {
                '': ''
            }
        });
        let lang = strings.getLanguage();
        this.state = {
            language: lang
        }
        console.log(this.props);
    }

    handleChangeLanguage(e) {
        let val = e.target.value;
        console.log('lang : ' + val);
        this.setState({language: val});
    }

    render() {
        return (
            <div className="App">
                <Header language={this.state.language} onLanguageChange={this.handleChangeLanguage.bind(this)}/>
                <div className="not-home">
                    <About language={this.state.language}/>
                    <Partners language={this.state.language}/>
                    <Services language={this.state.language}/>
                    <Ecology language={this.state.language}/>
                    <PackageFollow language={this.state.language}/>
                    <section id="contact">
                        <div className="row">
                            <div className="twelve col main-col">
                                <h2 style={{fontSize: "38px"}}>Contact</h2>
                                <div style={{textAlign: "center"}}>
                                    <h3>Pour tout renseignement, veuillez vous rapprocher de nos équipes par mail :</h3>
                                    <a href="mailto:contactca@groupetsm.fr"
                                       style={{fontSize: "28px"}}>contactca@groupetsm.fr</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*<Contact language={this.state.language}/>*/}
                </div>
                <Footer language={this.state.language}/>
            </div>
        );
    }
}

export default App;
