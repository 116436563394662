import React, {Component} from 'react';
import ReactPlayer from 'react-player/file'
import $ from 'jquery';
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    en: {
        desc: "Created in 2013, TSM Group is composed of many companies. \n" +
            "Two operating companies named TSM EXP and TSM LOC cumulating 83 light vehicles licences " +
            "and 22 super heavy-weight licenses. Furthermore a logistic storage company named TSM FRET " +
            "ease the logistic needed through 9500m² of warehouse located in Perpignan, Narbonne, Montpellier and Lyon.\n" +
            "We also exert the activity of freight forwarder, with the possibility to organize containers clearance. \n",
            // "To mutualize accounting, human resources and to manage the exploitation of the group, another company" +
            // "named TSM HOLDING has been created. \n" +
            // "On the year 2019, TSM Group generated around 9 millions euros of turnover. \n" +
            // "On the year 2020, TSM Group will generate around 12 millions euros of turnover. \n" +
            // "The perspectives for the year 2021 are to strengthen our positions on actual markets, and to open an office in Béziers during the year.",
        activity: 'Our activity'
    },
    fr: {
        desc: "Crée en 2013, le groupe TSM est composé de plusieurs entreprises.\n" +
            "Deux sociétés d'exploitations TSM EXP et TSM LOC comportant 83 licences véhicules léger\n" +
            "et 22 licenses Super Poids Lourd. De plus une société de stockage logistique TSM FRET permet\n" +
            "l'entrepôsage des marchandises au travers de 9500m² d'entrepôts répartis sur Perpignan, Narbonne,\n" +
            "Montpellier et Lyon.\n" +
            "Nous exerçons également l'activité de commisionnaire de transport\n" +
            "avec possibilité d'organiser le dédouanement de container.\n",
            // "Afin de mutualiser la comptabilitée ,les resources humaines et gérer l'exploitation du groupe,\n" +
            // "une autre société du nom de HOLDING TSM à été crée.\n" +
            // "Sur l’année 2019 le groupe TSM à a réalisé un CA d’environ 9 millions d’euros.\n" +
            // "Sur l’année 2020 le groupe TSM réalisera un CA aux alentour de 12 millions d’euros\n" +
            // "Les perspectives pour 2021 seront de renforcer la position du groupe TSM sur les marchés actuel,\n" +
            // "et d’ouvrir une agence sur Béziers courant d’année.",
        activity: "Notre activité"
    }
});

class About extends Component {

    state = {
        // Player state
        playing: true,
        controls: false,
        volume: 0,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: true,
        // Scroll state
        scrollY: 0,
        height: 0
    }

    handleProgress = state => {
        console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    scaleValueToRange = (value, valueMin, valueMax) => {
        return ((value - valueMin) / (valueMax - valueMin));
    }

    // onScroll = () => {
    //     const scrollY = $(window).scrollTop();
    //     console.log(`onScroll, window.scrollY: ${scrollY}`);
    //     if(scrollY < this.state.height * 1.5){
    //         let value = this.scaleValueToRange(scrollY,0,this.state.height*1.5);
    //         console.log('Value : ' + value);
    //         if(this.player != null)
    //             this.player.seekTo(value)
    //     }
    //     this.setState({
    //         scrollY: scrollY,
    //     });
    // }

    // onMouseMove = (event) => {
    //     console.log( "pageX: " + event.pageX + ", pageY: " + event.pageY );
    //     this.scaleValueToRange(12,0,this.state.height*1.5);
    //     let value = this.scaleValueToRange(event.pageX,0,2000);
    //     console.log('Value : ' + value);
    //     if(this.player != null)
    //         this.player.seekTo(value);
    // }

    // componentDidMount() {
    //     // $(window).scroll(this.onScroll);
    //     $( document ).on( "mousemove", this.onMouseMove);
    //     console.log('height : ' + $(window).height());
    //     this.setState({
    //         height: $(window).height()
    //     })
    // }

    ref = player => {
        this.player = player
    }

    render() {
        const {playing, controls, volume, muted, loop, played, loaded, duration, playbackRate} = this.state;
        strings.setLanguage(this.props.language);
        return (
            <section id="about">
                <div className="row">
                    <div className="main-col twelve col">
                        <h1>{strings.activity}</h1>
                        <div className="text-pic">
                            {/*<ReactPlayer*/}
                            {/*    ref={this.ref}*/}
                            {/*    className='react-player'*/}
                            {/*    width='100%'*/}
                            {/*    height='100%'*/}
                            {/*    url={process.env.PUBLIC_URL + '/assets/video/TSMNoBorderVideo.mp4'}*/}
                            {/*    playing={playing}*/}
                            {/*    controls={controls}*/}
                            {/*    loop={loop}*/}
                            {/*    playbackRate={playbackRate}*/}
                            {/*    volume={volume}*/}
                            {/*    muted={muted}*/}
                            {/*    onStart={() => console.log('onStart')}*/}
                            {/*    // onSeek={e => console.log('onSeek', e)}*/}
                            {/*    onProgress={this.handleProgress}*/}
                            {/*/>*/}
                            {/*<h1>{played}</h1>*/}
                            <img alt="truck" className="desc-image"
                                 src={process.env.PUBLIC_URL + '/assets/images/TSMWarehouse3.png'}/>
                            <p className="desc-text">
                                {strings.desc}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default About;
