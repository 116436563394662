import React, {Component} from 'react';
import AnimatedShowMore from 'react-animated-show-more';
import LocalizedStrings from "react-localization";
import {faTruckLoading, faKey, faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

let strings = new LocalizedStrings({
    en: {
        open: "Open",
        close: "Close",
        transportTitle: "Transport",
        rentTitle: "Rent",
        freightForwarderTitle: "Freight Forwarder",
        transportDesc: "Benefiting from their multiple warehouses, vehicles and deliverers, " +
            "TSM Group is mainly positioned on the logistic market." +
            "For a fast and qualitative service in the south of France don't hesitate anymore " +
            "Trust TSM Group to take care of your deliveries like Amazon and Fedex has done before. ",
        rentDesc: "TSM Group has diversified their activities to now include a vehicle renting service. "  +
            "You can right now rent one of our 95 light vehicles or if you need more space, one of our 44 heavy vehicles. " +
            "We also have an intern mecanic workshop so our vehicles stay at their top performance whenever you rent it. ",
        freightForwarderDesc: "Need to send wares outside France ? No problem TSM Group also pratice the activity of freight forwarder. " +
            "On demand it is also possible to organise the clearance of the containers. ",
    },
    fr: {
        open: "Plus",
        close: "Moins",
        transportTitle: "Transport",
        rentTitle: "Location",
        freightForwarderTitle: "Commissionnaire de transport",
        transportDesc: "De part ces multiples entrepôts, véhicules et livreurs, " +
            "le Groupe TSM est principalement positionné sur le marché de la logistique." +
            "Pour un service de qualité et rapide dans le Sud de la France n'hésitez plus." +
            "Faites confiance au Groupe TSM pour le transport de vos marchandises comme l'ont fait avant vous " +
            "des entreprises rennomés telles que Amazon, Fedex et ColisPrivé.",
        rentDesc: "Le Groupe TSM a su diversifier son activité afin de proposer un service de location de véhicules. " +
            "Vous pouvez dés à présent louer un de nos 95 véhicules léger ou bien si vous avez besoin de plus de place, un " +
            "de nos 44 véhicules poids lourd. Nous possédont aussi un atelier mécanique en interne afin que nos véhicules " +
            "restent d'une qualité irréprochable.",
        freightForwarderDesc: "Besoin d'envoyer des marchandises hors de la France ? Pas de soucis le Groupe TSM exerce aussi l'activité " +
            "de commissionaire de transport. Sur demande il est aussi possible d'organiser le dédouannement du/des containers " +
            "auprés des autorités locales."

    }
});

class Services extends Component {

    render() {
        strings.setLanguage(this.props.language);
        return (
            <section id="services">
                <div className="row">
                    <div className="main-col twelve col">
                        <h1>Services</h1>
                        <div className="row">
                            <div className="four column">
                                <AnimatedShowMore
                                    height={150}
                                    toggle={({ isOpen }) => isOpen ? strings.close : strings.open }
                                    speed={200}
                                    shadowColor="#713910ff">
                                    <div style={{textAlign: "center"}}>
                                        <FontAwesomeIcon icon={faTruckLoading} size="2x" color="#b29871ff"/>
                                    </div>
                                    <h3 style={{
                                        textAlign: 'center'
                                    }}>{strings.transportTitle}</h3>
                                    <p>{strings.transportDesc}</p>
                                </AnimatedShowMore>
                            </div>
                            <div className="four column">
                                <AnimatedShowMore
                                    height={150}
                                    toggle={({ isOpen }) => isOpen ? strings.close : strings.open }
                                    speed={200}
                                    shadowColor="#7b7975">
                                    <div style={{textAlign: "center"}}>
                                        <FontAwesomeIcon icon={faKey} size="2x" color="#7b7975"/>
                                    </div>
                                    <h3 style={{
                                        textAlign: 'center'
                                    }}>{strings.rentTitle}</h3>
                                    <p>{strings.rentDesc}</p>
                                </AnimatedShowMore>
                            </div>
                            <div className="four column">
                                <AnimatedShowMore
                                    height={150}
                                    toggle={({ isOpen }) => isOpen ? strings.close : strings.open }
                                    speed={200}
                                    shadowColor="#713910ff">
                                    <div style={{textAlign: "center"}}>
                                        <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" color="#b29871ff"/>
                                    </div>
                                    <h3 style={{
                                        textAlign: 'center'
                                    }}>{strings.freightForwarderTitle}</h3>
                                    <p>{strings.freightForwarderDesc}</p>
                                </AnimatedShowMore>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Services;
