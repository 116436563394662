import React, {Component} from 'react';
import LocalizedStrings from "react-localization";

const partners = [
    {
        name: 'Amazon',
        link: 'https://www.amazon.fr/',
    },
    {
        name: 'Chronopost',
        link: 'https://www.chronopost.fr/',
    },
    {
        name: 'FedEx',
        link: 'https://www.fedex.com/',
    },
    {
        name: 'GLS',
        link: 'https://gls-group.eu/',
    },
    {
        name: 'DPD',
        link: 'https://www.dpd.fr',
    },
    {
        name: 'Colis Privé',
        link: 'https://www.colisprive.fr/',
    },
    {
        name: 'DHL',
        link: 'https://www.dhl.com/',
    },
    {
        name: 'UPS',
        link: 'https://www.ups.com/fr/fr/',
    },
    {
        name: 'VIAPOST',
        link: 'https://www.viapost.fr/',
    },
    {
        name: 'Groupe Mazet',
        link: 'https://www.mazet.com/',
    },
    {
        name: 'XPO Logistics',
        link: 'https://europe.xpo.com/',
    },
];

let strings = new LocalizedStrings({
    en: {
        trust: "They trust us",
    },
    fr: {
        trust: "Il nous font confiance",
    }
});


class Partners extends Component {

    render() {
        strings.setLanguage(this.props.language);
        return (
            <section id="partners">
                <div className="row">
                    <div className="main-col twelve col">
                        <h1>{strings.trust}</h1>
                        <div className='row'>
                            {partners.map((partner, index) =>
                                <div className='four column' key={index}>
                                    <div className='portfolio-item'>
                                        <div className='hover-bg' style={{
                                            margin: "2vw"
                                        }}>
                                            {' '}
                                            <a
                                                href={partner.link}
                                                title='partner'
                                                data-lightbox-gallery='partner'
                                                target="_blank"
                                            >
                                                <div className='hover-text'>
                                                    <h4>{partner.name}</h4>
                                                </div>
                                                <img
                                                    src={process.env.PUBLIC_URL + `/assets/images/partners/partner${index}.svg`}
                                                    className='img-responsive'
                                                    alt='partner'
                                                />{' '}
                                            </a>{' '}
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default Partners;
