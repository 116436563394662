import React, {Component} from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import $ from "jquery";
import FollowPackageResponse from "./FollowPackageResponse";
import LocalizedStrings from "react-localization";
import {useLocation, useSearchParams} from "react-router-dom";

let strings = new LocalizedStrings({
    en: {
        packageFollow: "Track Package",
        textHelp: "Please enter package identifier",
        validate: "Submit"
    },
    fr: {
        packageFollow: "Suivi Colis",
        textHelp: "Renseignez le N° de suivi",
        validate: "Valider"
    }
});


class PackageFollow extends Component {


    constructor(props, context) {
        super(props, context);
        const search = window.location.search;
        let defaultValue = "";
        if(search)
            defaultValue = search.split("=")[1]
        this.state = {
            loading: false,
            noEvent: false,
            last_updated: null,
            proof: null,
            type: null,
            not_found: false,
            identifier: defaultValue
        }
    }

    contactSubmit(e) {
        e.preventDefault();
        this.setState({
            loading: true,
            last_updated: null,
            proof: null,
            type: null,
            partnerCode: null,
            not_found: false
        });
        // if(this.handleValidation()){
        // setup some local variables
        let $form = $("form");

        // Let's select and cache all the fields
        let $inputs = $form.find("input[name=identifier]");
        let identifier = $inputs.val();
        console.log(identifier);
        // Let's disable the inputs for the duration of the Ajax request.
        // Note: we disable elements AFTER the form data has been serialized.
        // Disabled form elements will not be serialized.
        $inputs.prop("disabled", true);
        // Fire off the request to /form.php
        let request = $.ajax({
            url: "https://www.groupetsm-api.com/api/Package/LastPackageEvent/?identifier=" + identifier,
            // url: "http://0.0.0.0:8000/api/Package/LastPackageEvent/?identifier=" + identifier,
            type: "get",
            headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
            'authorization': 'Token 57bf4cf65514eac204c524f8888d1cd7338750b4'
            }
        });
        let rThis = this;
        // Callback handler that will be called on success
        request.done(function (response, textStatus, jqXHR) {
            console.log('done');
            if(response === "No event")
                rThis.setState(
                    {
                        noEvent: true
                    }
                );
            else if ("partner" in response)
                rThis.setState(
                    {
                        partnerCode: response.partner
                    }
                );
            else
            rThis.setState(
                {
                    last_updated: response.last_updated,
                    type: response.type,
                    proof: response.proof,
                    package: response
                }
            );
        });

        // Callback handler that will be called on failure
        request.fail(function (jqXHR, textStatus, errorThrown) {
            // Log the error to the console
            console.error(
                "The following error occurred: " +
                textStatus, errorThrown, jqXHR
            );
            rThis.setState(
                {
                    not_found: true
                }
            );
        });

        // Callback handler that will be called regardless
        // if the request failed or succeeded
        request.always(function () {
            // Reenable the inputs
            $inputs.prop("disabled", false);
            rThis.setState({loading: false});
        });
        // } else {
        //     console.log(this.state.errors);
        // }
    }

    render() {
        strings.setLanguage(this.props.language);
        return (
            <section id="follow-package">
                <div className="row">
                    <div className="twelve col main-col">
                        <h1>{strings.packageFollow}</h1>
                        <p>{strings.textHelp}</p>
                        <form onSubmit={this.contactSubmit.bind(this)}>
                            <div className="input-button">
                                <input name="identifier" defaultValue={this.state.identifier}/>
                                <button>{strings.validate}</button>
                            </div>
                            <div style={{textAlign: "center", paddingTop: "20px"}}>
                                <ScaleLoader color="white" loading={this.state.loading} size={40}/>
                            </div>
                            <div id="response-container" hidden={this.state.type === null && !this.state.not_found && !this.state.noEvent && this.state.partnerCode == null}>
                                <FollowPackageResponse type={this.state.type} date={this.state.last_updated}
                                                       proof={this.state.proof} notFound={this.state.not_found}
                                                       noEvent={this.state.noEvent} partnerCode={this.state.partnerCode}
                                                       language={this.props.language} package={this.state.package ?? {}} />
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}

export default PackageFollow;
