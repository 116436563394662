import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faHouseUser,
    faWarehouse,
    faBox,
    faTruckMoving,
    faCheck,
    faTimes,
    faQuestion
} from '@fortawesome/free-solid-svg-icons'
import {motion} from "framer-motion";
import $ from "jquery";
import Container from "@material-ui/core/Container";
import LocalizedStrings from "react-localization";
import {Hidden, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import withStyles from "@material-ui/core/styles/withStyles";
import RoundMap from "./RoundMap";

let strings = new LocalizedStrings({
    en: {
        exp: "Your package is coming to our warehouse",
        aec: "We just received your package informations",
        att: "Your package is in our warehouse",
        rst: "Your package is being prepared for delivery",
        mlv: "Your package is being delivered",
        liv: "Your package has been delivered",
        paq: "Your package has been took at the warehouse",
        ren: "Your package wasn't delivered, if possible another delivery will come",
        renjrc: "Your package was never received in our warehouse",
        sou: "Your package has a problem, you will soon be contacted by our teams",
        notFound: "Package not found, please check the code you entered"
    },
    fr: {
        exp: "Votre colis arrive dans nos entrepôts",
        aec: "Nous venons de recevoir les informations de votre colis ",
        att: "Votre colis est dans nos entrepôts",
        rst: "Votre colis est en train d'être préparé à la livraison",
        mlv: "Votre colis est en train d'être livré",
        liv: "Votre colis à été livré",
        paq: "Votre colis à été pris à quai",
        ren: "Votre colis n'a pas pu être livré, il sera remis en livraison si possible",
        renjrc: "Votre colis n'a jamais été reçus dans nos entrepôts",
        sou: "Votre colis subit une erreur, vous allez bientôt être contacté par nos équipes",
        notFound: "Paquet non trouvé, veuillez vérifier le code entré"
    }
});

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient(90deg, rgba(10,80,156,1) 0%, rgba(15,89,147,1) 41%, rgba(0,212,255,1) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient(90deg, rgba(10,80,156,1) 0%, rgba(15,89,147,1) 41%, rgba(0,212,255,1) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 60,
        height: 60,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient(306deg, rgba(10,80,156,1) 0%, rgba(15,89,147,1) 41%, rgba(0,212,255,1) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient(306deg, rgba(10,80,156,1) 0%, rgba(15,89,147,1) 41%, rgba(0,212,255,1) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;
    const statusInfos = {
        "EXP": {
            "icon": faTruckMoving
        },
        "AEC": {
            "icon": faTruckMoving
        },
        "ATT": {
            "icon": faWarehouse
        },
        "ENL": {
            "icon": faWarehouse
        },
        "RST": {
            "icon": faBox
        },
        "MLV": {
            "icon": faTruckMoving
        },
        "LIV": {
            "icon": faHouseUser
        },
        "PAQ": {
            "icon": faCheck
        },
        "REN": {
            "icon": faTimes
        },
        "RENJRC": {
            "icon": faTimes
        },
        "SOU": {
            "icon": faTimes
        },
    }
    console.log(props);
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {<FontAwesomeIcon icon={Object.values(statusInfos)[props.icon - 1].icon} size="2x" style={{
                marginLeft: "0"
            }}/>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    stepper: {
        backgroundColor: "#2b2d2d"
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return [1, 2, 3, 4, 5];
}

function PackageStepper(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(1);
    const steps = getSteps();
    console.log(props);
    return (
        <div className={classes.root}>
            <Stepper className={classes.stepper} alternativeLabel activeStep={props.index}
                     connector={<ColorlibConnector/>}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}/>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}

const FollowPackageResponse = props => {
    strings.setLanguage(props.language);
    const statusInfos = {
        "EXP": {
            "text": strings.exp,
            "index": 0,
        },
        "AEC": {
            "text": strings.aec,
            "index": 0,
        },
        "ATT": {
            "text": strings.att,
            "index": 1,
        },
        "ENL": {
            "text": strings.att,
            "index": 1,
        },
        "RST": {
            "text": strings.rst,
            "index": 2,
        },
        "MLV": {
            "text": strings.mlv,
            "index": 3,
        },
        "LIV": {
            "text": strings.liv,
            "index": 4,
        },
        "PAQ": {
            "text": strings.paq,
            "index": 4,
        },
        "REN": {
            "text": strings.ren,
            "index": -1,
        },
        "RENJRC": {
            "text": strings.renjrc,
            "index": -1,
        },
        "SOU": {
            "text": strings.sou,
            "index": -1,
        },
    }

    console.log(props);
    if (props.type !== null) {
        let table = $('.table-follow');
        let code = props.type.code === "RENJRC" ? "RENJRC" : props.type.code.substr(0, 3);
        let dateObj = new Date(props.date);
        let icons = Object.keys(statusInfos).map((key, i) => statusInfos[key].icon);
        return (
            <div className="row">
                <div className="twelve columns">
                    <Container style={{textAlign: "center"}}>
                        <Hidden smDown>
                            {statusInfos[code].index !== -1 &&
                            <PackageStepper index={statusInfos[code].index}/>}
                        </Hidden>
                        <FontAwesomeIcon icon={statusInfos[code].icon} size="6x"/>
                        <h3>{statusInfos[code].text}</h3>
                        <h5>Date : {dateObj.toLocaleString()}</h5>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontSize: "large"}}>
                                        Date et heure
                                    </TableCell>
                                    <TableCell style={{fontSize: "large"}}>
                                        Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {props.package.events?.map(e => {
                                    const code = e.type.code === "RENJRC" ? e.type.code : e.type.code.substr(0, 3);
                                    // console.log(code,statusInfos[code]);
                                    return (<TableRow key={e.id}>
                                        <TableCell style={{fontSize: "large"}}>
                                            {new Date(e.date).toLocaleString()}
                                        </TableCell>
                                        <TableCell style={{fontSize: "large"}}>
                                            {statusInfos[code]?.text ?? code}
                                        </TableCell>
                                    </TableRow>);
                                })}
                            </TableBody>
                        </Table>
                        <br/>
                        {props.package.status === 2 && <RoundMap package={props.package}/>}
                        <div className="row">
                            <div className="six columns">
                                {(props.package.proofSignature != null && props.package.proofSignature != '') && <img src={props.package.proofSignature} alt="proofSignature"/>}
                            </div>
                            <div className="six columns">
                                {(props.package.proofPicture != null && props.package.proofPicture != '') && <img src={props.package.proofPicture} alt="proofPicture"/>}
                            </div>
                        </div>
                        <br/>
                        <a className="smoothscroll" href="/#contact">Réclamation, changement de date, informations...</a>
                    </Container>
                </div>
            </div>
        );
    }
    if (props.partnerCode) {
        console.log("In partner")
        return (
            <div className="row">
                <div className="twelve columns">
                    <Container style={{textAlign: "center"}}>
                        <h3>Suivez votre colis sur le site de notre partenaire :</h3>
                        <a href={`https://gls-group.eu/FR/fr/suivi-colis?match=${props.partnerCode}`}>{`https://gls-group.eu/FR/fr/suivi-colis?match=${props.partnerCode}`}</a>
                    </Container>
                </div>
            </div>
        )
    }

    if (props.notFound) {
        return (
            <div className="row">
                <div className="twelve columns">
                    <Container style={{textAlign: "center"}}>
                        <FontAwesomeIcon icon={faQuestion} size="6x"/>
                        <h3>{strings.notFound}</h3>
                    </Container>
                </div>
            </div>
        )
    }

    if (props.noEvent) {
        return (
            <div className="row">
                <div className="twelve columns">
                    <Container style={{textAlign: "center"}}>
                        <FontAwesomeIcon icon={faQuestion} size="6x"/>
                        <h3>Aucune informations encore disponible revenez plus tard</h3>
                    </Container>
                </div>
            </div>
        )
    }
    return (<div></div>);
}

export default FollowPackageResponse;
