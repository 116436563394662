import './App.scss';
import React, {Component, useState} from "react";
import Header, {NavBar} from "./components/Header";
import About from "./components/About";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import {BrowserRouter, useLocation} from "react-router-dom";
import Services from "./components/Services";
import PackageFollow from "./components/PackageFollow";
import Partners from "./components/Partners";
import LocalizedStrings from 'react-localization';
import Ecology from "./components/Ecology";
import AnimatedSlideShow from "./components/AnimatedSlideShow";


let strings = new LocalizedStrings({
    'fr': {
        '': ''
    }
});


const DataProtection = (props) => {
    const [lang, setLang] = useState(strings.getLanguage());

    const handleChangeLanguage = (e) => {
        let val = e.target.value;
        setLang(val);
    }

    return (
        <div className="App">
            {/*<div style={{*/}
            {/*    height: "6vh",*/}
            {/*    backgroundColor: "#ccc"*/}
            {/*}}/>*/}
            <NavBar language={lang} onLanguageChange={handleChangeLanguage}/>
            <div className="not-home">
                <section id="about">
                    <div className="row">
                        <div className="main-col twelve col" style={{color: "#ccc"}}>
                            <h1 style={{textAlign: "center"}}>Politique de protection des données personnelles</h1>
                            <h3>1. Préambule</h3>
                            <p>Groupe TSM, SAS dont le siège social est 7 Rue des caroubiers, 66000 Rivesaltes, en sa
                                qualité de responsable de traitement, s’est engagée dans une politique de respect de la
                                réglementation relative à la protection des données à caractère personnel.</p>
                            <p>Dans le cadre de son activité économique, Groupe TSM est, en effet, amenée à collecter
                                et à traiter des données à caractère personnel relatives à ses clients.</p>
                            <p>Groupe TSM a mis en place les moyens techniques nécessaires pour protéger les données à
                                caractère personnel qu’elle traite.</p>
                            <p>La présente politique a pour objet de présenter les engagements pris par le Groupe TSM
                                en matière de protection des données à caractère personnel.</p>

                            <h3>2. Les bases juridiques et les finalités de nos traitements de données</h3>
                            <p>Le Groupe TSM collecte vos données personnelles sur les bases juridiques suivantes :</p>
                            <p>Sur la base d’obligations précontractuelles et/ou contractuelles à votre égard ou à
                                l’égard d’un client de Groupe TSM (Article 6 b. du RGPD) et notamment afin d’assurer
                                :</p>
                            <ul style={{
                                color: "#ccc",
                                marginLeft: "10%"
                            }}>
                                <li>Les services d’expédition de colis et la prise en compte des modifications de
                                    Données de
                                    livraison
                                </li>
                                <li>Le suivi des services Groupe TSM</li>
                                <li>La communication avec les destinataires et d’autres parties concernant les
                                    services
                                </li>
                                <li>La réponse aux demandes d’informations (supplémentaires)</li>
                                <li>Le traitement des réclamations</li>
                                <li>La résolution des litiges</li>
                            </ul>

                            <h3>3. Catégorie de données traitées</h3>
                            <p>Les données personnelles vous concernant qui sont traitées par Groupe TSM sont les
                                suivantes :</p>

                            <p>Nom / Prénom, Adresse e-mail, Numéro(s) de téléphone, Adresse postale, Numéro de colis,
                                Complément(s) d’adresse et Lieu-dit, Informations/instructions de livraison
                                supplémentaires</p>

                            <h3>4. Les destinataires de vos données</h3>
                            <p>Les Données collectées font l’objet d’un traitement informatique et sont destinées à
                                l’usage
                                propre de Groupe TSM et, si requis, aux organismes publics, auxiliaires de justice,
                                officiers ministériels,
                                et pour les seules finalités définies au « 2) Bases juridiques et finalités du
                                traitement ».
                                <br/>
                                Groupe TSM veille à ce que seules les personnes habilitées puissent avoir accès à ces
                                données.</p>


                            <h3>5. Les durées pour lesquelles nous conservons vos données</h3>
                            <p>Groupe TSM conserve vos Données pour une durée de 3 ans à partir du dernier contact.
                                Constitue un « dernier contact » l’action suivante :<br/>

                                – La dernière communication en provenance de la personne à l’origine de la demande de
                                contact</p>


                            {/*Il est précisé que ces données peuvent être conservées suivant les règles de prescription*/}
                            {/*applicable en base archive intermédiaire et accès restreint afin notamment de se conformer*/}
                            {/*aux obligations légales applicables.*/}

                            <h3>6. La sécurité de vos données</h3>
                            <p>Groupe TSM accorde une importance particulière à la sécurité des données personnelles.
                                Des mesures techniques et organisationnelles appropriées sont mises en œuvre pour que les
                                données soient traitées de façon à garantir leur protection contre la perte,
                                la destruction ou les dégâts d’origine accidentelle qui pourraient porter atteinte
                                à leur confidentialité ou à leur intégrité.<br/>
                                Groupe TSM met ainsi en œuvre des mesures qui respectent les principes de protection dès
                                la conception et de protection par défaut des données traitées.</p>


                            <h3>7. Les droits qui vous sont reconnus</h3>
                            <p>
                            Groupe TSM est soucieux du respect des droits qui vous sont accordés dans le cadre des
                            traitements de données qu’il met en œuvre, pour vous garantir des traitements équitables et
                            transparents compte tenu des circonstances particulières et du contexte dans lesquels
                            vos données personnelles sont traitées.<br/><br/>

                            Votre droit d’accès :<br/><br/>

                            Par ce droit, vous avez la confirmation que vos données personnelles sont ou ne sont pas
                            traitées et lorsqu’elles le sont, vous disposez du droit de demander une copie de vos
                            données et des informations vous concernant.<br/><br/>

                            Votre droit à la rectification de vos données :<br/><br/>

                            Vous pouvez nous demander que vos données personnelles soient, selon les cas, rectifiées,
                            complétées si elles sont inexactes, incomplètes, équivoques, périmées.<br/><br/>

                            Votre droit à l’effacement de vos données :<br/><br/>

                            Vous pouvez demander l’effacement de vos données personnelles lorsque l’un des motifs
                            suivants s’applique :<br/><br/>
                            – les données personnelles ne sont plus nécessaires au regard des finalités pour lesquelles
                            elles ont été collectées ou traitées d’une autre manière ;<br/><br/>
                            – vous retirez le consentement préalablement donné ;<br/><br/>
                            – vous vous opposez au traitement de vos données personnelles lorsqu’il n’existe pas de
                            motif légitime impérieux pour le traitement ;<br/><br/>
                            – le traitement de données personnelles n’est pas conforme aux dispositions de la
                            législation et de la réglementation applicable.<br/><br/>

                            Votre attention est attirée sur le fait que le droit à l’effacement des données n’est pas un
                            droit général et qu’il ne pourra y être fait droit que si un des motifs prévus dans la
                            réglementation applicable est présent.<br/><br/>

                            Ainsi, si aucun de ces motifs n’est présent, Groupe TSM ne pourra répondre favorablement
                            à votre demande ; tel sera le cas si elle est tenue de conserver les données en raison d’une
                            obligation légale ou réglementaire ou pour la constatation, l’exercice ou la défense de
                            droits en justice.<br/><br/>

                            Votre droit à la limitation des traitements de données :<br/><br/>

                            Vous pouvez demander la limitation du traitement de vos données personnelles dans les
                            cas prévus par la législation et la réglementation.<br/><br/>

                            Votre droit de vous opposer aux traitements de données :<br/><br/>

                            Vous disposez du droit de vous opposer à tout moment, pour des raisons tenant à
                            votre situation particulière, à un traitement de vos données personnelles dont la base
                            juridique est l’intérêt légitime.<br/><br/>

                            En cas d’exercice d’un tel droit d’opposition, Groupe TSM veillera à ne plus traiter vos
                            données personnelles dans le cadre du traitement concerné sauf si nous pouvons démontrer que
                            nous pouvons avoir des motifs légitimes et impérieux pour maintenir ce traitement.
                            Ces motifs devront être supérieurs à vos intérêts et à vos droits et libertés, ou le
                            traitement se justifier pour la constatation, l’exercice ou la défense de droits en justice.<br/><br/>

                            Votre droit à la portabilité de vos données :<br/><br/>

                            Vous disposez du droit à la portabilité de vos données personnelles.
                            Nous attirons votre attention sur le fait qu’il ne s’agit pas d’un droit général.
                            En effet, toutes les données de tous les traitements ne sont pas portables et ce droit
                            ne concerne que les traitements automatisés à l’exclusion des traitements manuels ou
                            papiers.<br/><br/>

                            Ce droit est limité aux traitements dont la base juridique est votre consentement
                            ou l’exécution des mesures précontractuelles ou d’un contrat.<br/><br/>

                            Le droit à la portabilité ne peut pas porter atteinte aux droits et libertés de tiers
                            telles que celles protégées par le secret des affaires.<br/><br/>

                            Votre droit d’introduire une réclamation :<br/><br/>

                            Vous avez le droit d’introduire une réclamation auprès de la Cnil
                            (3 place de Fontenoy 75007 Paris) sur le territoire français.<br/><br/>

                            Votre droit de définir des directives post-mortem :<br/><br/>

                            Vous avez la possibilité de définir des directives particulières relatives
                            à la conservation, à l’effacement et à la communication de vos données personnelles après
                            votre décès auprès de nos services selon les modalités ci-après définies.
                            Ces directives particulières ne concerneront que les traitements mis en œuvre p ar nos soins
                            et seront limitées à ce seul périmètre.<br/><br/>

                            Les modalités d’exercice de vos droits :<br/><br/>

                            Les demandes relatives à l’exercice de vos droits s’effectuent par email à
                            l’adresse clement.mitjana@xentech.pro ou par courrier à l’adresse postale suivante :<br/><br/>

                            DPO<br/>
                            Xentech<br/><br/>

                            10 Place de la République<br/><br/>
                            66270 Le Soler, Occitanie<br/><br/>

                            en justifiant de votre identité par tout moyen,
                            tel que par la communication de la copie de votre carte d’identité.<br/><br/>

                            Cette adresse email n’est pas destinée à recevoir vos candidatures,
                            des questions relatives aux livraisons de vos commandes ou à traiter vos réclamations.
                            Pour ces questions, merci de contacter le service client avec le formulaire de contact.
                            et de votre code postal.<br/><br/>
                            </p>
                            <h3>8. Modification du présent document</h3>
                            <p>
                            Nous vous invitons à consulter régulièrement cette politique sur notre site internet.
                            Elle pourra faire l’objet de mises à jour
                            Mars 2022</p>
                        </div>
                    </div>
                </section>
            </div>
            <Footer language={lang}/>
        </div>
    );
}

export default DataProtection;
