import {Component} from "react";
import Footer from "./Footer";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
    en:{
        title0: "Presentation",
        subtitle0: "Our services",
        title1: "Our Engagement",
        subtitle1: "Ecology",
        title2: "Information",
        subtitle2: "Partners",
        companyName: "TSM Group"
    },
    fr: {
        title0: "Présentation",
        subtitle0: "Nos services",
        title1: "Notre Engagement",
        subtitle1: "Ecologie",
        title2: "Informations",
        subtitle2: "partenaires",
        companyName: "Groupe TSM"
    }
});

class AnimatedSlideShow extends Component {
    constructor(props) {
        super(props);

        this.IMAGE_PARTS = 8;

        this.changeTO = null;
        this.AUTOCHANGE_TIME = 10000;

        this.state = { activeSlide: -1, prevSlide: -1, sliderReady: false };
    }

    componentWillUnmount() {
        window.clearTimeout(this.changeTO);
    }

    componentDidMount() {
        this.runAutochangeTO();
        setTimeout(() => {
            this.setState({ activeSlide: 0, sliderReady: true });
        }, 0);
    }

    runAutochangeTO() {
        this.changeTO = setTimeout(() => {
            this.changeSlides(1);
            this.runAutochangeTO();
        }, this.AUTOCHANGE_TIME);
    }

    changeSlides(change) {
        window.clearTimeout(this.changeTO);
        const { length } = this.props.slides;
        const prevSlide = this.state.activeSlide;
        let activeSlide = prevSlide + change;
        if (activeSlide < 0) activeSlide = length - 1;
        if (activeSlide >= length) activeSlide = 0;
        this.setState({ activeSlide, prevSlide });
    }

    render() {
        let classNames = require('classnames');
        const { activeSlide, prevSlide, sliderReady } = this.state;
        strings.setLanguage(this.props.language);
        return (
            <div className={classNames('slider', { 's--ready': sliderReady })}>
                <h2 className="slider__top-heading">{strings.companyName}</h2>
                <div className="slider__slides">
                    {this.props.slides.map((slide, index) => (
                        <div
                            className={classNames('slider__slide', { 's--active': activeSlide === index, 's--prev': prevSlide === index  })}
                            key={`s${index}`}
                        >
                            <div className="slider__slide-content">
                                <h3 className="slider__slide-subheading">{strings['title' + index]}</h3>
                                <h2 className="slider__slide-heading">
                                    {strings['subtitle' + index].split('').map(l => <span>{l}</span>)}
                                </h2>
                                <a className="slider__slide-readmore smoothscroll" href={slide.link}>Plus</a>
                            </div>
                            <div className="slider__slide-parts">
                                {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
                                    <div className="slider__slide-part" key={i}>
                                        <div className="slider__slide-part-inner" style={{ backgroundImage: `url(${slide.img})` }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default AnimatedSlideShow;


